/* Base styles */
.estate-detail-header-text {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0%;
  margin-bottom: 10px;
  color: #000;
}

.estate-detail-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.title-text {
  font-family: "Lato !important";
  font-size: 72px;
  font-weight: 800;
  line-height: 86px;
}

.estate-detail-btn {
  margin-top: 1rem;
  border-radius: 60px;
  color: #fff !important;
  background-color: #510000;
  padding: 5px 60px;

  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 16px;
}

.precautinary-title-text {
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 16px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.precautinary-item-text {
  margin-top: 1rem !important;
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.estate-detail-property-title-text {
  color: #510000;
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
}

.estate-detail-property-img-thumbnail-container {
  display: flex !important;
  flex-direction: row !important;
}

.estate-detail-property-img {
  width: 639px !important;
  height: 437px !important;
  border-radius: 10px !important;
}

.estate-detail-property-img-thumbnail {
  width: 134px !important;
  height: 92px !important;
  border-radius: 5px !important;
}

.estate-detail-property-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
}

.estate-detail-property-facility-item-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
}

.estate-detail-property-price {
  margin: 1rem 0rem;
  color: #510000 !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(18px, 5vw, 20px) !important;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  margin-bottom: 0.3rem !important;
}

.estate-detail-share-btn {
  border-radius: 60px;
  color: #510000 !important;
  border: 1px solid #510000 !important;
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 16px;
}

.faq-sub-header-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  vertical-align: middle;
  text-transform: uppercase;
}

.faq-header-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
  vertical-align: middle;
}

.faq-description-text {
  color: #0a0a0a;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1%;
  vertical-align: middle;
}

.faq-item-title-icon {
  margin-right: 1rem;
  color: #0a0a0a;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: 0.5px;
  vertical-align: middle;
}

.faq-item-title-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  vertical-align: middle;
}

.faq-item-description-text {
  color: #0a0a0a;
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  vertical-align: middle;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* .our-leadership {
    grid-template-columns: 100%;
  } */

  .header-text {
    font-size: 52px;
    font-weight: 800;
    line-height: 86px;
  }
}

@media (max-width: 576px) {
  .header-text {
    font-size: 42px;
    font-weight: 800;
    line-height: 86px;
  }

  .estate-detail-share-btn {
    border-radius: 30px !important;
  }

  .estate-detail-share-btn-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .faq-sub-header-text {
    color: #0a0a0a;
    font-family: "Abhaya Libre !important";
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
  }

  .faq-header-text {
    color: #0a0a0a;
    font-family: "Abhaya Libre !important";
    font-size: 42px;
    font-weight: 800;
    line-height: 42px;
    letter-spacing: 1%;
  }
}
