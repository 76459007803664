/* Your existing CSS */
.motto {
  padding: 0px !important;
  margin: 0px !important;
  color: #510000 !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: clamp(14px, 5vw, 24px) !important;
  line-height: 45px;
  letter-spacing: 0%;
  vertical-align: middle;
  text-align: center;
}

.nav-link-style {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: clamp(14px, 5vw, 16px) !important;
}

.become-an-affiliate-btn {
  color: #510000;
  margin-left: 1rem;
  border-radius: 60px;
  border: 1px solid #510000;
}

.buy-now-btn {
  margin-left: 1rem;
  border-radius: 60px;
  color: #fff !important;
  background-color: #510000;
  border: 1px solid #510000;
  padding: 0px 25px;
}

.nav-item-list {
  font-family: "Poppins" !important;
}

/* Mobile responsive styles */
@media (max-width: 991px) {
  .dropdown-menu {
    position: static !important;
    float: none;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    padding: 0;
    margin: 0;
    border: none;
    border-left: 2px solid #510000;
  }

  .dropdown-menu.show {
    max-height: 300px;
    padding: 10px 0;
    overflow-y: auto;
  }

  .nav-btn-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    width: 100%;
  }

  .become-an-affiliate-btn,
  .buy-now-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0.5rem;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .menu-items-container {
    margin-bottom: 2rem;
  }

  .nav-btn-container {
    margin-top: 1rem;
  }

  .become-an-affiliate-btn {
    margin-left: 0rem;
  }

  .buy-now-btn {
    margin-left: 0rem;
    margin-top: 0.5rem;
  }
}
