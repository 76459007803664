.form-description-text {
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -1%;
}

.form-title-text {
  color: #510000 !important;
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -1%;
}

/* Form control styling improvements */
.form-control {
  border: 1px solid #000;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  color: #333;
}

/* Value styling - ensures the text entered by users is in Poppins */
input.form-control,
select.form-control,
select.form-control option {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #333 !important;
  font-weight: 500 !important;
}

/* Placeholder styling */
.form-control::placeholder {
  font-family: "Poppins", sans-serif;
  color: #999;
  opacity: 1;
}

/* For Internet Explorer */
.form-control:-ms-input-placeholder {
  font-family: "Poppins", sans-serif;
  color: #999;
}

/* For Microsoft Edge */
.form-control::-ms-input-placeholder {
  font-family: "Poppins", sans-serif;
  color: #999;
}

/* Hover state to increase visibility */
.form-control:hover {
  border-color: #333;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Focus state */
.form-control:focus {
  border-color: #510000;
  background-color: #fff;
  box-shadow: 0 0 0 2px rgba(81, 0, 0, 0.2);
  outline: none;
}

/* Error state */
.form-control.is-invalid {
  border-color: #dc3545;
  background-image: none;
  padding-right: 12px;
  box-shadow: 0 1px 3px rgba(220, 53, 69, 0.2);
}

/* Label styling */
.font-fifteen {
  font-size: 15px;
  color: #333;
  margin-bottom: 6px;
  font-family: "Poppins", sans-serif;
}

.font-500 {
  font-weight: 500;
}

/* Error message */
.text-danger {
  font-size: 13px;
  margin-top: 5px;
  display: block;
  font-family: "Poppins", sans-serif;
}

/* Select element specific styling */
select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  padding-right: 2.5rem;
}

/* Container styling for better spacing */
.mb-20 {
  margin-bottom: 20px;
}

.select-option {
  font-family: "Poppins", sans-serif;
}

.btn-style {
  border-radius: 60px;
  color: #fff !important;
  font-family: "Poppins" !important;
  padding: 0px 25px;
}