.container-padding {
  height: 100vh !important;
}
.contact-us-header-text,
.contact-us-sub-header-text {
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  color: #fff !important;
}

.contact-us-sub-header-text {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

.contact-us-header-text {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
  vertical-align: middle;
}

.contact-us-text {
  display: flex;
  flex-direction: column;
  margin-left: 288.55px;
}

.contact-us-item-title-text {
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  color: #fff !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: -1%;
}

.contact-us-item-description-text {
  margin-bottom: 0.3rem;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: -1%;
}

.contact-us-grid {
  display: grid;
  align-items: center !important;
  gap: 3rem;
  grid-template-columns: 50% 50%;
}

.contact-form {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  background-color: white;
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
}

.btn-send {
  display: inline-block;
  background-color: white;
  color: #510000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 60px;
  border: 1px solid #510000;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 500px;
}

.btn-send:hover {
  background-color: #510000;
  color: white;
  border-color: white;
}

@media (max-width: 768px) {
  .contact-us-text {
    margin-left: 100px;
  }
}

@media (max-width: 576px) {
  .contact-us-item-title-text {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    color: #fff !important;
    font-family: "Abhaya Libre" !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: -1%;
  }

  .contact-us-item-description-text {
    margin-bottom: 0.3rem;
    color: #fff !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: -1%;
  }

  .contact-us-grid {
    margin-top: 3rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: 100%;
  }

  .contact-us-text {
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact-us-form {
    width: 90%;
  }

  .contact-form {
    margin-top: 20px;
    width: 100%;
    max-width: 90%;
    /* max-width: 400px; */
  }

  .contact-us-img-container {
    padding-right: 0px !important;
    padding-left: 0px;
    background-color: #fff;
  }
}

/* .contact-section {
  background-color: #510000;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.contact-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 20px;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.contact-info {
  padding-right: 20px;
}

.contact-subtitle {
  font-family: "Abhaya Libre", serif;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0%;
  line-height: 32px;
}

.contact-title {
  font-family: "Abhaya Libre", serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
  margin-bottom: 40px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-item {
  margin-bottom: 20px;
}

.item-title {
  font-family: "Abhaya Libre", serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 8px;
}

.item-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 4px;
}

.with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.phone-icon {
  background-image: url("../../../public/assets/images/testimonials/phone-icon.png");
}

.mail-icon {
  background-image: url("../../../public/assets/images/testimonials/mail-icon.png");
}

.twitter-icon {
  background-image: url("../../../public/assets/images/testimonials/twitter-x-icon.png");
}

.location-icon {
  background-image: url("../../../public/assets/images/testimonials/location-icon.png");
}

.contact-form {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  background-color: white;
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
}

.btn-send {
  display: inline-block;
  background-color: white;
  color: #510000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 60px;
  border: 1px solid #510000;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.btn-send:hover {
  background-color: #510000;
  color: white;
  border-color: white;
}

.contact-image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
  display: block;
}

@media (max-width: 992px) {
  .contact-title {
    font-size: 38px;
    line-height: 48px;
  }
  
  .contact-grid {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
  
  .contact-info {
    padding-right: 0;
    order: 1;
  }
  
  .contact-image {
    order: 0;
    margin-bottom: 20px;
  }
  
  .contact-image img {
    max-width: 100%;
  }
  
  .contact-title {
    font-size: 32px;
    line-height: 42px;
  }
  
  .contact-form {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .contact-container {
    padding: 40px 15px;
  }
  
  .contact-subtitle {
    font-size: 20px;
  }
  
  .contact-title {
    font-size: 28px;
    line-height: 36px;
  }
  
  .item-title {
    font-size: 15px;
  }
  
  .btn-send {
    max-width: 100%;
  }
} */
